import React from "react";
import Helmet from "react-helmet";
import urljoin from "url-join";
import PropTypes from "prop-types";

// Local
import config from "data/SiteConfig";

console.log(config);

// Aah yes SEO is just a component
const SEO = ({ postNode, postPath, postSEO }) => {
	let title;
	let description;
	let image;
	let postURL;

	if (postSEO) {
		const postMeta = postNode.frontmatter;
		({ title } = postMeta);
		description = postMeta.description
			? postMeta.description
			: postNode.excerpt;
		image = postMeta.social_image;

		postURL = urljoin(config.siteUrl, config.pathPrefix, postPath);

		// Remove duplicate slashes
		postURL = postURL.replace(".com//", ".com/");
	} else {
		title = config.siteTitle;
		description = config.siteDescription;
		image = config.siteLogo;
	}

	let blogURL = urljoin(config.siteUrl, config.pathPrefix);
	blogURL = blogURL.replace(".com//", ".com/");
	// const schemaOrgJSONLD = [
	// 	{
	// 		"@context": "http://schema.org",
	// 		"@type": "WebSite",
	// 		url: blogURL,
	// 		name: title,
	// 		alternateName: config.siteTitleAlt ? config.siteTitleAlt : ""
	// 	}
	// ];
	// if (postSEO) {
	// 	schemaOrgJSONLD.push(
	// 		{
	// 			"@context": "http://schema.org",
	// 			"@type": "BreadcrumbList",
	// 			itemListElement: [
	// 				{
	// 					"@type": "ListItem",
	// 					position: 1,
	// 					item: {
	// 						"@id": postURL,
	// 						name: title,
	// 						image
	// 					}
	// 				}
	// 			]
	// 		},
	// 		{
	// 			"@context": "http://schema.org",
	// 			"@type": "BlogPosting",
	// 			url: blogURL,
	// 			name: title,
	// 			alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
	// 			headline: title,
	// 			image: {
	// 				"@type": "ImageObject",
	// 				url: image
	// 			},
	// 			description
	// 		}
	// 	);
	// }
	let facebookImg;
	let twitterImg;
	let twitterImgCard;
	if (image) {
		let imagePath = urljoin(config.siteUrl, config.pathPrefix, image);
		imagePath = imagePath.replace(".com//", ".com/");

		// NOTE: React Fragement in Helmet causes hard to spot errors
		facebookImg = <meta property="og:image" content={imagePath} />;
		twitterImg = <meta name="twitter:image" content={imagePath} />;
		twitterImgCard = (
			<meta name="twitter:card" content="summary_large_image" />
		);
	}

	return (
		<Helmet>
			{/* General tags */}
			<meta name="description" content={description} />
			<meta name="author" content="Dominic Woodman" />
			<link rel="canonical" href={postURL} />

			{/* Schema.org tags */}
			{/* <script type="application/ld+json">
				{JSON.stringify(schemaOrgJSONLD)}
			</script> */}

			{/* OpenGraph tags */}
			<meta property="og:url" content={postSEO ? postURL : blogURL} />
			{postSEO ? <meta property="og:type" content="article" /> : null}
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			{facebookImg}

			{/* Twitter Card tags */}

			<meta
				name="twitter:creator"
				content={config.userTwitter ? config.userTwitter : ""}
			/>
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			{twitterImg}
			{twitterImgCard}

			{/* Favicon */}
			{/* We only provide one size because at this point we think people do a good
			job of scaling and the size is neglible. */}
			<link
				rel="shortcut icon"
				type="image/png"
				sizes="192x192"
				href="/images/logos/favicon-192.png"
			/>
		</Helmet>
	);
};

SEO.propTypes = {
	postNode: PropTypes.shape(),
	postPath: PropTypes.string,
	postSEO: PropTypes.shape()
};

export default SEO;
