import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import { Disqus } from "gatsby-plugin-disqus";

// Local imports
import Layout from "src/layout";
import Bio from "src/components/generic/Bio/Bio";
import PostTags from "src/components/generic/PostTags/PostTags";
import PostUserJourney from "src/components/generic/PostUserJourney/PostUserJourney";
import SocialLinks from "src/components/generic/SocialLinks/SocialLinks";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";

// Style imports
import styles from "src/templates/Post/post.module.scss";
import "src/templates/Post/prism-okaidia.css";

export default ({ data, pageContext }) => {
	const { slug, nexttitle, nextslug, prevtitle, prevslug } = pageContext;
	const postNode = data.markdownRemark;
	const post = postNode.frontmatter;
	const { date } = postNode.fields;
	if (!post.id) {
		post.id = slug;
	}

	let postMetaRendered;
	if (!post.standalone) {
		postMetaRendered = (
			<p className={styles.postMeta}>
				{date} &mdash; {postNode.timeToRead} Min Read{" "}
			</p>
		);
	}

	const liveSiteUrl = `${config.siteUrl}${slug}`;
	const disqusConfig = {
		url: liveSiteUrl
	};

	let socialAndBio;
	if (!post.standalone) {
		socialAndBio = (
			<React.Fragment>
				<Bio config={config} />
				<div className={styles.postMeta}>
					<SocialLinks postPath={slug} postNode={postNode} />
				</div>
				<h2 className="m-b-lg">Comments</h2>
				<Disqus config={disqusConfig} />
			</React.Fragment>
		);
	}

	let userJourneyRendered;
	if (post.userJourney && post.userJourney !== "blank") {
		userJourneyRendered = (
			<PostUserJourney userJourney={post.userJourney} />
		);
	}

	return (
		<Layout>
			<main className={`${styles.postHero} container`}>
				<Helmet>
					<title>{`${post.title} | ${config.siteTitle}`}</title>
				</Helmet>
				<SEO postPath={slug} postNode={postNode} postSEO />
				<section className="hero is-bold">
					<div className={`hero-body ${styles.heroBodyOverride}`}>
						<div
							className={`container ${styles.containerModifier}`}
						>
							<div className={styles.contentWidth}>
								<h1 className="title">{post.h1title}</h1>
								{postMetaRendered}
								<div className={styles.postMeta}>
									{userJourneyRendered}
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className={`${styles.postBody}`}>
					<div className={`container ${styles.containerModifier}`}>
						<div className={styles.contentWidth}>
							<div
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: postNode.html
								}}
							/>
							{socialAndBio}
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};

//

/* eslint no-undef: "off" */
export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			timeToRead
			excerpt
			frontmatter {
				title
				date
				h1title
				social_image
				categories
				tags
				userJourney
				standalone
			}
			fields {
				slug
				date(formatString: "MMMM DD, YYYY")
			}
		}
	}
`;
