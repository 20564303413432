import React from "react";
import PropTypes from "prop-types";

import { Link } from "gatsby";
import { userJourneyBlurb } from "src/templates/UserJourney/UserJourney";
import { userJourneyMapping } from "src/routesConstants";

import styles from "./PostUserJourney.module.scss";

const PostUserJourney = ({ userJourney }) => {
	console.log(userJourney);
	return (
		<div className={styles.tagContainer}>
			<Link to={userJourneyMapping[userJourney]}>
				<span>{userJourneyBlurb[userJourney].prettyTitle}</span>
			</Link>
		</div>
	);
};

PostUserJourney.propTypes = {
	tags: PropTypes.string.isRequired
};

export default PostUserJourney;
