import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { Follow } from "react-twitter-widgets";
import styles from "./Bio.module.scss";

const Bio = ({ config, expanded }) => {
	const data = useStaticQuery(graphql`
		query {
			fileName: file(relativePath: { eq: "images/pages/face.jpg" }) {
				childImageSharp {
					fixed(width: 80, height: 80) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`);

	return (
		<div className={styles.bioContainer}>
			<Img
				className={styles.avatar}
				fixed={data.fileName.childImageSharp.fixed}
				alt={config.userName}
				fadeIn={false}
			/>
			<span>
				By Dominic Woodman. This bio is mostly here because it looked
				good in mock-ups. {` `}
				<Follow
					username={config.userTwitter}
					options={{ count: expanded ? true : "none" }}
				/>
			</span>
		</div>
	);
};

export default Bio;
